const
    $burgerBtn = $('.burger'),
    $mobileMenu = $('.mobile-menu'),
    $anchor = $('.anchor-link'),
    $slideArrow = $('.slider-arrow'),
    $forms = $("form");

const phone_field = '.input_phone';
$(phone_field).each(function () {
    $(this).inputmask("+7 (999) 999-99-99");

});

// *** VALIDATOR RULES *** //

const
    EMAIL_RULES = {
        required: true,
        email: true
    },
    NAME_RULES = {
        required: true
    },
    PHONE_RULES = {
        required: true,
        minlength: 18
    };

// *** FORM TYPES **** //

const FORM_TYPES = {
    "type-1": "Форма в хедере",
    "type-2": "Мой метод",
    "type-3": "Чек лист" ,
    "type-4": "Форма в футере"
};

const REQUEST_TYPES = {
    "type-1": `Cпасибо за заявку! Мы c Вами свяжемся в ближайшее время.`,
    "type-2": "Спасибо за заявку! Я отправлю примеры продаж в ближайшее время.",
    "type-3": "Спасибо за заявку! Я отправлю чек-лист в ближайшее время.",
    "type-4": `Cпасибо за заявку! Мы в Вами свяжемся в ближайшее время.`
};


// ***** FUNCTIONS ****** //

// *** IS BURGER *** //

function showMobileMenu() {
    $('body').css("overflow" , "hidden");
    $($mobileMenu).addClass('menu-active');
    $($burgerBtn).addClass('burger--active');
}

function hideMobileMenu() {
    $('body').css("overflow" , "auto");
    $($mobileMenu).removeClass('menu-active');
    $($burgerBtn).removeClass('burger--active');
}

// *** IS SLIDER **** //

function ToggleSlide () {
    let
        $activeSlide = $('.youtube-slide--active'),
        $nextSlide = $('.youtube-slide--next');

    $($nextSlide)
        .addClass('youtube-slide--active')
        .removeClass('youtube-slide--next');

    $($activeSlide)
        .addClass('youtube-slide--next')
        .removeClass('youtube-slide--active');
}

const AnaliticsHandler = (eventTarget, isSubmit = false) => {
    let eventType = $(eventTarget).data("event");
    if(isSubmit) {
        eventType = "submit";
    }

    switch (eventType) {
        case "phone" :
            ym(56946427, 'reachGoal', 'form_phone');
            console.log("phone");
            break;

        case "blog" :
            ym(56946427, 'reachGoal', 'v_blog_vk');
            console.log('blog');
            break;

        case "telegram":
            ym(56946427, 'reachGoal', 'v_blog_tg');
            console.log('telega');
            break;

        case "submit":
            ym(56946427, 'reachGoal', 'form_lead_book');
            console.log('submit');
            break;

        default:
            return false;

    }
};

// *** IS MODAL *** //

function showModal($text) {
    $('.overlay').fadeIn('40');
    $('.modal').css({
        "display": "-webkit-box",
        "display": "-ms-flexbox",
        "display": "flex"
    });
    setTimeout(function () {
        $('.modal')
            .addClass('modal-active')
            .find('p').text($text);
    },10);
}

function modalHide() {
    $('.overlay').fadeOut('40');
    $('.modal').removeClass('modal-active');
    setTimeout(function () {
        $('.modal').hide();
    },400);
}

// *** IS SEND *** //

function sendForm(email, name, $phone, formType) {
    let $type = FORM_TYPES[formType];
    $.ajax({
        type: "POST",
        url: "./send.php",
        dataType: "json",
        data: {
            email: email,
            name: name,
            phone: $phone,
            formType: $type
        },
        success: function (data) {
            if (data.status) {
                let $text = REQUEST_TYPES[formType];
                showModal($text);
            }
        },
        error: function (jqXHR, exception) {
            showModal("Произошла ошибка. Пожалуйста, попробуйте позже");
            console.log(jqXHR) ;
            console.log(exception)   ;
        }
    });
}

// ******* FUNCTIONS END ********* //

$('.close , .overlay').click(function (event) {
    event.preventDefault();
    modalHide();
});

//***** IS BURGER MENU *******//

$($burgerBtn).click(function () {
    if($($mobileMenu).is('.menu-active')) {
        hideMobileMenu();
    } else {
        showMobileMenu();
    }
});


$(document).ready(function () {

    // *** VALIDATOR **** //

    $($forms).each(function () {
        $(this).validate({
            rules: {
                hero_phone: PHONE_RULES,
                specification_phone: PHONE_RULES,
                method_phone: PHONE_RULES,
                footer_phone: PHONE_RULES,
                footer_name: NAME_RULES,
                specification_name: NAME_RULES
            }
        });
    });

    //***** IS SCROLL TO ANCHOR ****** //

    $($anchor).click(function (event) {
        event.preventDefault();

        let $this = $(this),
            $target = $($this).attr('href'),
            $offset = $($target).offset().top;

        if($(this).is('.mobile-menu__link')) {
            hideMobileMenu();
        }

        $('html').animate({
            scrollTop: $offset
        }, 1200);
    });

    // ****** IS YOUTUBE SLIDER ****** //

    $($slideArrow).click(function () {
        ToggleSlide();
    });

    $('.youtube-slide').click(function (event) {
        if($(this).is('.youtube-slide--active')) {
            return false;
        }
        ToggleSlide();
    });

    $('.youtube-link').click(function (event) {
        event.preventDefault();
        if($(event.target !== ".youtube-slide--next") ) {
            if($(event.target).is(".slider-arrow")) {
                return false;
            }
            let $href = $('.youtube-slide--active').data('href');
            window.open($href, '_blank');
        }
    });

    // fix slider height

    if($ (window).width() > 640 ) {
        let $slideHeight = $('.makup').height();
        $('.slider-wrapper').height($slideHeight);

        $(window).resize(function () {
            let $slideHeight = $('.makup').height();
            $('.slider-wrapper').height($slideHeight);
        });
    }
});


// *** SEND FORM ***


$('button[type="submit"]').click(function (event) {
    event.preventDefault();

    let
        $this = $(this).parents('form'),
        $name = $($this).find('input[type="text"]').val(),
        $email = $($this).find('input[type="email"]').val(),
        $phone = $($this).find('input[type="tel"]').val(),
        $type = $($this).data('type'),
        $validStatus = $this.valid();

    AnaliticsHandler(event.target);
    if($validStatus) {
        AnaliticsHandler(event.target, true);
        sendForm($email, $name, $phone, $type);
    }
});

$('a').click((event) => {
    let those = $(event.target);
    if($(those).data('event') !== undefined) {
      AnaliticsHandler(those);
    }
});


$('.fixed-btn').click(()=> {
    ym(56946427, 'reachGoal', 'get_manager');
});








